import { api } from 'reduxStore/api';
import { GEOCODE_URL } from 'configs/urls';
import { GET_LAYOUT_ELEMENTS, SAVE_LAYOUT_ELEMENT } from 'constants/api';

const getGeolocation = () =>
  new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        resolve(coords);
      }, reject);
    }
  });

const geolocationBaseQuery = async () => {
  const coords = await getGeolocation();

  return { data: coords };
};
const layoutElementsWithChangedEnabled = (dispatch, layoutElementId, isEnabled) => {
  dispatch(
    settingsApi.util.updateQueryData('getLayoutElement', undefined, draft => {
      draft.result = draft.result.map(item =>
        item.layoutElementId === layoutElementId ? { ...item, isEnabled: isEnabled } : item
      );
    })
  );
};

export const settingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCountryByGeolocation: builder.query({
      queryFn: async () => {
        try {
          const { data: coordinatesResult } = await geolocationBaseQuery();
          const { latitude, longitude } = coordinatesResult || {};

          const params = new URLSearchParams({ latitude, longitude }).toString();
          const url = `${GEOCODE_URL}?${params}`;

          const response = await fetch(url, { method: 'GET' });
          const data = await response.json();
          return { data };
        } catch (error) {
          return { error: error.message };
        }
      },
    }),
    getLayoutElement: builder.query({
      query: body => ({
        url: GET_LAYOUT_ELEMENTS,
        method: 'POST',
        body,
      }),
      transformResponse: response => {
        const { result } = response;

        return {
          result: result.map(item => ({
            ...item,
            isEnabled: Boolean(Number(item.isEnabled)),
            layoutElementId: Number(item.layoutElementId),
          })),
        };
      },
    }),
    saveLayoutElements: builder.mutation({
      query: body => ({
        url: SAVE_LAYOUT_ELEMENT,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { layoutElementId, isEnabled } = body?.PartnerLayoutElement ?? {};
        layoutElementsWithChangedEnabled(dispatch, layoutElementId, isEnabled);
        const { data } = await queryFulfilled;

        if (!data?.result) {
          layoutElementsWithChangedEnabled(dispatch, layoutElementId, !isEnabled);
        }
      },
    }),
  }),
});

export const { useGetCountryByGeolocationQuery, useGetLayoutElementQuery, useSaveLayoutElementsMutation } = settingsApi;
